import React, { useRef } from 'react';
import "./style.css";
import logo from "../assets/logo.webp";
import categoriesImg from "../assets/Catagoies.webp";
import chatImg from "../assets/Chat.webp";
import video from "../assets/file.mp4"
import Form from '../components/Form';
import appleStore from "../assets/apple-store.webp"
import googlePlay from "../assets/google-play.webp"
import { useAnimateScroll } from '../utils/useAnimateScroll';

const Home = () => {
  const downloadRef = useRef(null)

  const animateScroll = useAnimateScroll;

  return (
    <div className='home'>
      <nav  >
        <img src={logo} alt="logo" />
      </nav>
      <h1>LIVE HELP</h1>
      <div className='container1' >
        <h2>CHAT with AN AUTO ADVISOR On demand</h2>
        <div className='home_imgsContainer' >
          <img src={categoriesImg} alt="categories" />
          <img src={chatImg} alt="chat" />
        </div>
      </div>
      <div className='container2'>
        <svg onClick={() => animateScroll({ id: "", ref: downloadRef, duration: 2000 })} preserveAspectRatio="xMidYMid meet" data-bbox="6.443 47.497 187.114 105.008" xmlns="http://www.w3.org/2000/svg" viewBox="6.443 47.497 187.114 105.008" role="presentation" aria-hidden="true">
          <g>
            <path d="M174.476 51.261l-74.704 74.193L25.578 50.75c-4.287-4.316-11.566-4.341-15.882-.054s-4.341 11.565-.055 15.882l82.107 82.673c4.287 4.316 11.566 4.341 15.882.055l82.673-82.107c4.316-4.287 4.341-11.566.055-15.882s-11.235-4.342-15.882-.056z"></path>
          </g>
        </svg>
        <h3>Information is power</h3>
        <div className='home_text'>Dealers and auto shops relay on your lack of knowledge to overcharge
          {" "} <span>fact check</span>  their quote in minutes using lifevize</div>
        <h3>advice made in the u.s.a.</h3>
        <div className='home_text'>advisors are real people based in the united states
          {" "}<span>Get quality</span>   advice from from quality advisors</div>
        <h3>money back guarantee</h3>
        <div className='home_text'>
          If you are not provided appropriate advice, you will be refunded.
          Try lifevize {" "} <span>risk free </span>  now
        </div>
      </div>
      <div className='home_video' >
        <video controls>
          <source src={video} type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Form ref={downloadRef} />
      <div className='home_subHeading' >Areas of advice</div>
      <div className='home_areas' >
        <div>
          <span>Engine issues</span>
          <span>Overheating</span>
          <span>Transmission</span>
          <span>Tires/wheels</span>
          <span>brakes</span>
          <span>steering</span>
          <span>Heading 3</span>
        </div>
        <div>
          <span>Electrical</span>
          <span>interior</span>
          <span>ride quality</span>
          <span>paint/body</span>
          <span ref={downloadRef}>windshield/glass</span>
          <span>Heading 3</span>
          <span >Heading 3</span>
        </div>

      </div>
      <div className='home_subHeading downloadHeading'  >Download the app</div>
      <div className='home_download' >
        <img src={appleStore} alt="store" />
        <img src={googlePlay} alt="google" />
      </div>
      <div className='home_footer' >
        <h4>get Connected to an advisor now</h4>
        <img src={logo} alt="logo" />
      </div>
      <div className='bottom' />

    </div>
  )
}

export default Home