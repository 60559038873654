import React, { forwardRef, useState } from 'react'
import "./style.css"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from 'axios'
import OtpInput from 'react-otp-input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { baseURL } from '../../utils'
import { useAnimateScroll } from '../../utils/useAnimateScroll'


const Form = forwardRef((props, ref) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [userId, setUserId] = useState("")
  const [formLoading, setFormLoading] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [otp, setOtp] = useState("")
  const [otpFormOpen, setOtpFormOpen] = useState(false)
  const [invalidOtp, setInvalidOtp] = useState(false)

  const animateScroll = useAnimateScroll;

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setPhone("");
    setZipCode("")
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (invalidPhone) {
      return;
    }
    const data = {
      firstName,
      lastName,
      email,
      password,
      zipCode,
      phone,
    }
    setFormLoading(true)
    try {
      axios.post(`${baseURL}/customer/sign-up`, data)
        .then(res => {
          setOtpFormOpen(true)
          setUserId(res?.data?.user?.id)
          setFormLoading(false)

        })
        .catch(e => {
          toast.error(e?.response?.data?.error)
          setFormLoading(false)

        })
    } catch (e) {
      console.log(e)
    }
  }


  const onChangeOtp = (e) => {
    setOtp(e)
    if (e?.length === 4) {
      const data = {
        user: userId,
        otp: e
      }
      try {
        axios.post(`${baseURL}/advisor/verify-otp`, data)
          .then(res => {
            setOtpFormOpen(false)
            toast.success(res?.data?.message)
            setOtp("")
            resetForm();
            setInvalidOtp(false)
            animateScroll({ id: "", ref, duration: 1500 })
          })
          .catch(e => {
            setInvalidOtp(true)
            console.log(e?.response?.data?.error)
          })
      } catch (e) {
        console.log(e)
      }
    }
  }

  const emailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  return (
    <>
      <ToastContainer
        autoClose={2000}
        position="bottom-right"
      />
      <form className='form' onSubmit={onSubmit} >
        <h3>Create a free account now</h3>
        <div className='form_inputContainer' >
          <label>First Name*</label>
          <input type="text" value={firstName} required onChange={e => setFirstName(e.target.value)} placeholder='' />
        </div>
        <div className='form_inputContainer' >
          <label>Last Name*</label>
          <input type="text" value={lastName} required onChange={e => setLastName(e.target.value)} placeholder='' />
        </div>
        <div className={invalidEmail ? 'form_inputContainer form_inputError' : 'form_inputContainer'} >
          <label >Email*</label>
          <input type="email" value={email}
            onBlur={() => {
              if (!emailValid.test(email)) {
                setInvalidEmail(true)
              } else {
                setInvalidEmail(false)
              }
            }}
            onChange={e => {
              setEmail(e.target.value)
              if (invalidEmail) {
                if (emailValid.test(e.target.value)) {
                  setInvalidEmail(false)
                }
              }
            }
            }

            required placeholder='' />
          <span>Email is invalid.</span>
        </div>
        <div className={invalidPassword ? 'form_inputContainer form_inputError' : 'form_inputContainer'}  >
          <label  >Password*</label>
          <input type="password" value={password} onBlur={() => {
            if (password.length < 6) {
              setInvalidPassword(true)
            } else {
              setInvalidPassword(false)
            }
          }}
            required
            onChange={e => {
              setPassword(e.target.value)
              if (invalidPassword && e.target.value) {
                if (e.target.value.length >= 6) {
                  setInvalidPassword(false)
                }
              }
            }}
            placeholder='Password' />
          <span >Password should be at least 6 characters long.</span>
        </div>
        <div className={invalidPhone ? "form_inputContainer form_inputError" : 'form_inputContainer'} >
          <label >Phone*</label>
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="US"
            value={phone}
            required
            onChange={(e) => {
              setPhone(e)
              if (invalidPhone && e) {
                if (isValidPhoneNumber(e)) {
                  setInvalidPhone(false)
                }
              }
            }}
            onBlur={() => {
              if (phone) {
                if (isValidPhoneNumber(phone)) {
                  setInvalidPhone(false)
                } else {
                  setInvalidPhone(true)
                }
              } else {
                setInvalidPhone(true)

              }
            }}
          />
        </div>
        <div className='form_inputContainer' >
          <label>Zip Code</label>
          <input type="number" value={zipCode} onChange={e => setZipCode(e.target.value)} placeholder="Zip Code" />
        </div>
        <button disabled={!lastName || !email || !password || !phone || !firstName || invalidPhone || invalidPassword || invalidEmail}  >{formLoading ? "Submitting..." : "Submit"}</button>
      </form>
      {otpFormOpen && (
        <div className='optContainer' >
          <div className={invalidOtp ? " otp otpError" : 'otp'}  >
            {/* <div className='otp_X' onClick={() => setOtpFormOpen(false)} >X</div> */}
            <h3>Enter Your OTP code</h3>
            <OtpInput
              value={otp}
              onChange={onChangeOtp}
              numInputs={4}
              separator={<span>-</span>}
            />
          </div>
        </div>
      )}

    </>
  )
}
)
export default Form;

